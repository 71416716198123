@import url('./scroll-indicator.css');

* {
    margin: 0;
    font-family: sans-serif;
    text-decoration: none;
}

h1 {
    padding: 1rem;
    color: #8018ff;
    font-weight: 500;
    font-size: 1.2rem;
}

p { 
    text-align: justify;
    padding: 2rem;
    font-size: 1.1rem;
    color:#333;
    align-self: center;
}

/* General Classes  */

.sticky {
    background-color:#fff;
    position: sticky;
    top: 0
}

.flex-col {
    color: #333;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: Inter, sans-serif;
}

.repo_info {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.repo_link {
    color: #8e32ff;
    font-size: 0.9rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
}

.content_scroll_icon {
    font-size: 2rem;
    color:rgba(84, 84, 84, 0.5);
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.content_text {
    margin-right: 2rem;
    margin-left:2rem;
    overflow-y: scroll;
    scroll-margin: 5rem;
    background-color: rgba(214, 214, 214, 0.5);
    scrollbar-color: rgb(255, 255, 255) rgba(84, 84, 84, 0.5);
    scrollbar-width:auto;
}

.code {
    color: #626262;
    background-color: rgb(245, 245, 245);
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 0.5rem;
}

.copy-button {
    padding: 0.5rem;
    background-color: rgb(246, 246, 246);
    border: none;
    cursor: pointer;

}

.copy-button > i {
    color: rgba(84, 84, 84, 0.5);;
    font-size: 1.1rem;
}

.copy-button > i :hover {
  transform: scale(5rem);
}

.banner {
	width: 200px;
	padding: 15px;
	background-color: #8e32ff;
	color: #fff;
	text-align: center;
	transform: rotate(-45deg) translate(-28%, -35%);
	box-shadow: 0px 5px 5px rgba(0,0,0,0.4);
	text-transform: uppercase;
	text-shadow: 0 2px 2px rgba(0,0,0,0.4);
}

