/* Scroll */

.scroll_box {
    background: rgb(245, 245, 245);
    height: 20vh;
    border:#b7b7b7;
    border-style:solid;
    border-width: 0.05rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.scroll_bar {
    background: rgb(179, 179, 179);
    position: relative;
    top: 35% ;
    height: 2rem;
    max-width: 100%;
    margin-left: 2rem;
    margin-right: 2rem;
}
  
.scroll_indicator {
    background: linear-gradient(to right ,  #5b16b0, #8e32ff, #af70fb);
    height: 2rem;
}

.scroll_info {
    color:#333;
    font-size: 1rem;
    padding: 1rem;
}